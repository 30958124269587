import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      blog: allPostsYaml {
        edges {
          node {
            title
            abstract
            slug
            date
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Blog |" />
      <h1 className="blog-header">Thoughts</h1>
      <section className="blog">
        {data.blog.edges.map(({ node }, id) => (
          <div
            key={node.title}
            style={{
              marginBottom: `2em`,
              position: `relative`,
            }}
          >
            <h1 className="blog-post-title">
              <Link to={`/blog/${node.slug}`} state={{ title: node.title }}>
                {node.title}
              </Link>
            </h1>
            <p
              style={{
                fontSize: `.8em`,
                fontStyle: "italic",
                textTransform: "uppercase",
              }}
            >
              {node.date}
            </p>
            <p>{node.abstract}</p>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export default BlogPage;
